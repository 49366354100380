import { Container, Flex, Spinner } from "@chakra-ui/react";
import axios from "axios";
import React, { createRef, useEffect, useState } from "react";
import MDEditor from '@uiw/react-md-editor';

export default function Museu(){

    const [ carregando, alterarCarregando ] = useState(true);
    const [ texto, alterarTexto ] = useState("");
    const refIframe = createRef();

    useEffect(() => {
        carregarDados();

    }, []);

    async function carregarDados(){


        try{

            let { data } = await axios.get(`/museu`);
            alterarTexto(data.textoMuseu);
            alterarCarregando(false);

        }catch({response}){}
    }

    // carregar a configuração do texto
    if(carregando){
        return <>
            <Flex
                p={10}
                align="center"
                justify="center"
            >
                <Spinner />
            </Flex>
        </>
    }

    return <>

        <Container
            maxW='container.sm'
            mt={0}
            my={10}
        >
            <MDEditor.Markdown source={texto} />
        </Container>
    </>

}