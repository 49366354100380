import React, { useState } from "react";
import { Box, Flex, Image, Container, Button, Text, useMediaQuery, useColorMode, useColorModeValue, Divider, Link } from "@chakra-ui/react";
import { FaMoon, FaSun, FaWhatsapp, FaUser, FaList, FaHome, FaSuitcase, FaPowerOff } from 'react-icons/fa';
import { useLogin } from './../Login/LoginContext';
import { useNavigate } from "react-router-dom";
// import logo1 from "./../../Assets/logo1.png";
// import logo2 from "./../../Assets/logo2.png";
import logo from "./../../Assets/nova/logo5.png";
import "./index.scss";


// { (login.autenticado && ["S"].includes(login.dados.tipoUsuario)) ?


export default function Cabecalho(){

    // estados
    const login = useLogin();
    const { toggleColorMode } = useColorMode();
    const [ maiorQue411 ] = useMediaQuery('(min-width: 411px)');
    const [ maiorQue768 ] = useMediaQuery('(min-width: 768px)');
    const [ maiorQue850 ] = useMediaQuery('(min-width: 850px)');

    const corTexto = useColorModeValue("museu.600", "white");
    const corFundo = useColorModeValue("white", "museu.600");
    // const logo = useColorModeValue(logo1, logo2);
    const iconeTema = useColorModeValue(<FaMoon />, <FaSun />);
    const [mostrarMenu, alterarMostrarMenu] = useState(false);
    const navigate = useNavigate();
    

    let MenuOpcoes = () => {
        return <>
            <div onHover={() => { console.log("entrou carai") }} className="menu">
                <Text variant="linkMenu" onClick={() => navigate("museu")}>Museu</Text>
                <div className="submenu" hidden> 
                    {/* TEMP */}
                    <Text variant="linkMenu" onClick={() => navigate("museu/institucional")}>Institucional</Text>
                    {/* <Text variant="linkMenu" onClick={() => navigate("acervo")}>Mantenedora</Text> */}
                    {/* <Text variant="linkMenu" onClick={() => navigate("acervo")}>Programa educativo</Text> */}
                    {/* <Text variant="linkMenu" onClick={() => navigate("acervo")}>Programa de acervo</Text> */}
                    {/* <Text variant="linkMenu" onClick={() => navigate("acervo")}>Exposições</Text> */}
                    {/* <Text variant="linkMenu" onClick={() => navigate("acervo")}>Eventos</Text> */}
                    {/* <Text variant="linkMenu" onClick={() => navigate("acervo")}>Agende sua visita</Text> */}
                    {/* <Text variant="linkMenu" onClick={() => navigate("acervo")}>Agenda do museu</Text> */}
                </div>
                
            </div>
            <Text variant="linkMenu" onClick={() => navigate("eventos")}>Eventos</Text>
            <Text variant="linkMenu" onClick={() => navigate("acervo")}>Acervo</Text>
            <Text variant="linkMenu" onClick={() => navigate("passeios")}>Passeios</Text>
            <Text variant="linkMenu" onClick={() => navigate("passeios-comprar")}>Bilheteria</Text>
            <Text variant="linkMenu" onClick={() => navigate("sac")}>SAC</Text>
        </>
    }

    const botacaoCabecalhoStyle = {
        color: corTexto,
        borderColor: "museu.600",
        borderWidth: maiorQue850 ? 2 : 1,
        rounded: 2,
        size: maiorQue850 ? "md" : "sm",
        fontSize: maiorQue850 ? "md" : "sm"
    };

    const IconeTemaBotao = ({...props}) => {

        return <>
            <Button 
                color={corTexto} 
                bg={"none"}
                borderWidth={0}
                rounded={2}
                onClick={toggleColorMode}
                mr={2}
                size={maiorQue768 ? "md" : "sm"}
                fontSize={maiorQue768 ? "md" : "sm"}
                {...props}
            >
                {iconeTema}
            </Button>
        </>
    }

    const AutenticadoOpcoes = () => {

        return <>
            { (login.autenticado) ?
                <>
                    <Button 
                        {...botacaoCabecalhoStyle}
                        onClick={() => { navigate("/painel-usuario"); }}
                        mr={2}
                        leftIcon={<FaHome />}
                        iconSpacing={maiorQue411 ? 2 : 0}
                    >
                       {maiorQue411 ? "Meu perfil" : null}
                    </Button>
                    <Button 
                        {...botacaoCabecalhoStyle}
                        onClick={() => {
                            login.sair();
                        }}
                        leftIcon={<FaPowerOff />}
                        iconSpacing={maiorQue411 ? 2 : 0}
                    >
                        {maiorQue411 ? "Sair" : null}
                    </Button>
                </>
                :
                <Button 
                {...botacaoCabecalhoStyle}
                    onClick={() => { navigate("/login"); }}
                >
                    <FaUser /> <Box ml={2}>Entrar</Box>
                </Button>
            }
        </>
    }

    return <>
        <Box
            // bg={"white"}
            bg={corFundo}
        >
            { (maiorQue850) &&
                <>
                    <Container
                        maxW='container.xl'
                    >
                        <Flex
                            p={3}
                            direction='row'
                            flex={1}
                        >
                            <Box>
                                <Image 
                                    style={{"object-fit":"contain"}}
                                    width={50}
                                    height={100}
                                    src={logo}
                                    onClick={() => navigate("")}
                                    cursor={"pointer"}
                                />
                            </Box>
                            <Flex
                                flex={1}
                                direction="column"
                            >
                                <Flex 
                                    px={10}
                                    flex={1} 
                                    justifyContent="space-between"
                                    align="flex-start"
                                >
                                    <Box>
                                        
                                        <IconeTemaBotao />

                                        <AutenticadoOpcoes />

                                    </Box>
                                    <Flex align="center">

                                        { (login.autenticado && ["S", "A", "V", "O"].includes(login.dados.tipoUsuario)) &&
                                            <>
                                                <Button 
                                                    color={corTexto} 
                                                    borderColor="museu.600" 
                                                    borderWidth={2} 
                                                    rounded={2}
                                                    onClick={() => { navigate("/painel"); }}
                                                >
                                                    <FaSuitcase /> <Box ml={2}>Painel</Box>
                                                </Button>
                                                <Box h={5} borderLeft={1} borderWidth={1} borderColor="museu.600" display="inline" mx={5}></Box>
                                            </>
                                        }

                                        <Flex 
                                            as={Link} 
                                            align="center" 
                                            color={corTexto}
                                            href="https://api.whatsapp.com/send/?phone=5548988681414"
                                            target="_blank"
                                        >
                                            <FaWhatsapp /> <Box ml={2} >(48) 9 8868-1414</Box>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex
                                    px={10}
                                    direction={"row"}
                                    justifyContent="space-between"
                                    textTransform="uppercase"
                                    color={corTexto}
                                >
                                    <MenuOpcoes />
                                </Flex>
                            </Flex>
                        </Flex>
                    </Container>
                </>
            }

            { (!maiorQue850) && 
                <>
                    <Box
                        w="100%"
                        bg={corFundo}
                    >
                        {/* <Box>
                            <Flex align="center" justify="center" pt={3} >
                                <FaWhatsapp /> <Box ml={2} fontSize="xs" >(48) 9 8868-1414</Box>
                            </Flex>
                        </Box> */}
                        <Flex
                            p={3}
                            direction='row'
                            flex={1}
                            alignItems="center"
                        >
                            <Box>
                                <Image 
                                    boxSize="50px"
                                    src={logo}
                                />
                            </Box>

                            <Flex 
                                px={3}
                                flex={1} 
                                justifyContent="space-between"
                                align="flex-start"
                                direction="row"
                            >
                                <Box>
                                    <Button 
                                        {...botacaoCabecalhoStyle}
                                        ml={1} 
                                        onClick={() => alterarMostrarMenu(!mostrarMenu)}
                                        px={1}
                                    >
                                        <FaList />
                                    </Button>

                                    <IconeTemaBotao ml={2} mr={0} />

                                </Box>
                                <Box>

                                    <AutenticadoOpcoes />

                                    {/* <Button 
                                        color={corTexto} 
                                        borderColor="museu.600" 
                                        borderWidth={1} 
                                        rounded={2}
                                        fontSize={"sm"}
                                        px={1}
                                        // py={0}
                                        
                                    >
                                        <FaUser /> <Box ml={2}>Entrar</Box>
                                    </Button>
                                    <Button 
                                        color={corTexto} 
                                        borderColor="museu.600" 
                                        borderWidth={1} 
                                        rounded={2}
                                        ml={1} 
                                        onClick={toggleColorMode}
                                        fontSize={"xs"}
                                        px={1}
                                    >
                                        {iconeTema}
                                    </Button> */}
                                </Box>
                            </Flex>                            
                        </Flex>

                        {mostrarMenu && <>
                            <Flex
                                direction="column"
                                align="center"
                                p={2}
                                pt={0}
                                pb={4}
                            >
                                <MenuOpcoes />
                            </Flex>
                        </>}
                    </Box>
                </>
            }

        </Box>
    </>

}